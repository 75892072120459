import s from './Footer.module.css'
import cn from 'classnames'
import { Navbar, Nav, Container, NavDropdown, Row, Col } from 'react-bootstrap';
import Link from 'next/link'


const Footer = () => {

    return <div
        className={cn(s.root)}
    ></div>
}

export default Footer;