import React, { FC, useMemo, useEffect, useContext } from 'react'
import { VariantModal } from 'types/Form'
import Loading from 'components/layout/loading/Loading'

export interface State {
    sidebarCollapsed: boolean
    header: THeader
    modalContent: any
    modalVariant: VariantModal
    modalIsOpened: boolean
}

export type TBreadcrumbs = Array<{
    title: string,
    link: string
}>

export type TSearchbar = {
    inputReference: string,
    title: string
}

export type THeader = {
    type: "BREADCRUMBS"
    content: TBreadcrumbs
}
    | {
        type: "SEARCHBAR",
        content: TSearchbar
    } | { type: "NULL" }

const initialState: State = {
    sidebarCollapsed: false,
    header: { type: "NULL" },
    modalContent: null,
    modalVariant: "default",
    modalIsOpened: false,
}

type Action =
    {
        type: 'SIDEBAR_COLLAPSE'
    }
    | {
        type: 'SIDEBAR_EXPAND',
    }
    | {
        type: 'HEADER_SET_BREADCRUMBS'
        value: TBreadcrumbs
    }
    | {
        type: 'HEADER_SET_SEARCHBAR'
        value: TSearchbar
    }
    | {
        type: 'HEADER_SET_NULL'
    }
    | {
        type: 'SET_MODAL_CONTENT',
        value: any
    }
    | {
        type: 'OPEN_MODAL'
    }
    | {
        type: 'CLOSE_MODAL'
    }
    | {
        type: 'SET_MODAL_VARIANT',
        value: VariantModal
    }
    |
    {
        type: 'MODAL_LOADING_START'
    }
    |
    {
        type: 'MODAL_LOADING_DONE'
    }


export const UIContext = React.createContext<State | any>(initialState)

UIContext.displayName = 'UIContext'

function uiReducer(state: State, action: Action) {
    switch (action.type) {
        case 'SIDEBAR_COLLAPSE': {
            return {
                ...state,
                sidebarCollapsed: true
            }
        }
        case 'SIDEBAR_EXPAND': {
            return {
                ...state,
                sidebarCollapsed: false
            }
        }
        case 'HEADER_SET_BREADCRUMBS': {
            let header: THeader = {
                type: "BREADCRUMBS",
                content: action.value
            }
            return {
                ...state,
                header
            }
        }
        case 'HEADER_SET_SEARCHBAR': {
            let header: THeader = {
                type: "SEARCHBAR",
                content: action.value
            }
            return {
                ...state,
                header
            }
        }
        case 'HEADER_SET_NULL': {
            let header: THeader = { type: "NULL" }
            return {
                ...state,
                header
            }
        }
        case 'SET_MODAL_CONTENT': {
            return {
                ...state,
                modalContent: action.value
            }
        }
        case 'OPEN_MODAL': {
            return {
                ...state,
                modalIsOpened: true
            }
        }
        case 'CLOSE_MODAL': {
            return {
                ...state,
                modalIsOpened: false
            }
        }
        case 'SET_MODAL_VARIANT': {
            return {
                ...state,
                modalVariant: action.value
            }
        }
        case 'MODAL_LOADING_START': {
            return {
                ...state,
                modalContent: <Loading />,
                modalIsOpened: true
            }
        }
        case 'MODAL_LOADING_DONE': {
            return {
                ...state,
                modalContent: <Loading />,
                modalIsOpened: false
            }
        }
    }
}

export const UIProvider: FC = (props) => {
    const [state, dispatch] = React.useReducer(uiReducer, initialState);

    const openSidebar = () => dispatch({ type: "SIDEBAR_EXPAND" });
    const closeSidebar = () => dispatch({ type: "SIDEBAR_COLLAPSE" })

    const setBreadcrumbs = (breadcrumbs: TBreadcrumbs) => dispatch({ type: "HEADER_SET_BREADCRUMBS", value: breadcrumbs })
    const setSearchbar = (searchbar: TSearchbar) => dispatch({ type: "HEADER_SET_SEARCHBAR", value: searchbar })
    const setHeaderToNull = () => { dispatch({ type: "HEADER_SET_NULL" }) }
    const setModalContent = (content: any) => { dispatch({ type: "SET_MODAL_CONTENT", value: content }) }
    const openModal = () => { dispatch({ type: 'OPEN_MODAL' }) };
    const closeModal = () => { dispatch({ type: 'CLOSE_MODAL' }) };
    const setModalVariant = (variant: VariantModal) => { dispatch({ type: "SET_MODAL_VARIANT", value: variant }) }

    const startLoading = () => { dispatch({ type: "MODAL_LOADING_START" }) };
    const closeLoading = () => { dispatch({ type: "MODAL_LOADING_DONE" }) };

    const value = useMemo(
        () => ({
            ...state,
            openModal,
            closeModal,
            setModalContent,
            setModalVariant,
            openSidebar,
            closeSidebar,
            setBreadcrumbs,
            setSearchbar,
            setHeaderToNull,
            startLoading,
            closeLoading
        }),
        [state]
    )

    return <UIContext.Provider value={value} {...props} />
}

export const useUI = () => {
    const context = React.useContext(UIContext)
    if (context === undefined) {
        throw new Error(`useUI must be used within a UIProvider`)
    }
    return context
}

export const ManagedUIContext: FC = ({ children }) => (
    <UIProvider>
        {children}
    </UIProvider>
)