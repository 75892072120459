import s from "./Sidebar.module.css";
import cn from "classnames";
import { Navbar, Nav, Container, NavDropdown, Row, Col } from "react-bootstrap";
import Link from "next/link";
import { Logo, Icons } from "components/utils";
import Avatar from "../avatar/Avatar";
import { useUI } from "contexts/UI/UIManager";
import { useAuth0 } from "@auth0/auth0-react";

const menuItems: Array<{ img: JSX.Element; title: string; link?: string }> = [
  {
    img: <Icons.Users />,
    title: "Clients",
    link: "/clients",
  },

  {
      img: <Icons.Stocks />,
      title: "Deposits",
      link: "/stocks"
  }
];
const menuItems2: Array<{ img: JSX.Element; title: string; link?: string }> = [
  // {
  //     img: <Icons.Chat />,
  //     title: "Messages",
  // },
  // {
  //     img: <Icons.Settings />,
  //     title: "Settings",
  // },
];
const RenderMenuItems = (
  menuItems: Array<{ img: JSX.Element; title: string; link?: string }>
) =>
  menuItems.map((item) => (
    <Link href={item.link ?? "/"} key={item.title}>
      <li className={cn(s.listItem)}>
        <span className={cn(s.itemImage)}>{item.img}</span>
        <span className={cn(s.itemText)}>{item.title}</span>
      </li>
    </Link>
  ));

const Sidebar = () => {
  const { logout } = useAuth0();

  const { sidebarCollapsed, openSidebar, closeSidebar } = useUI();

  return (
    <div className={cn(s.root, { [s.minimized]: sidebarCollapsed })}>
      <div className={cn(s.logoContainer)}>
        <div className={cn(s.logo)}>
          <Logo />
        </div>
      </div>
      <ul className={cn(s.list)}>{RenderMenuItems(menuItems)}</ul>
      <div className={cn(s.separator)}></div>
      <ul className={cn(s.list)}>
        {RenderMenuItems(menuItems2)}
        <li
          className={cn(s.listItem)}
          onClick={() => {
            logout({ returnTo: window.location.origin });
          }}
        >
          <span className={cn(s.itemImage)}>
            <Icons.Signout />
          </span>
          <span className={cn(s.itemText)}>Logout</span>
        </li>
      </ul>
      <Avatar />
      <button onClick={closeSidebar} className={cn(s.collapseButton)}>
        <Icons.ArrowFromRight />
      </button>
      <button onClick={openSidebar} className={cn(s.expandButton)}>
        <Icons.ArrowToRight />
      </button>
    </div>
  );
};

export default Sidebar;
