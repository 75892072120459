import { FC, useEffect, useRef, useState } from 'react'
import s from './Layout.module.css'
import cn from 'classnames'
import Footer from './footer'
import Sidebar from './sidebar'
import Content from './content'
import { ManagedUIContext } from 'contexts/UI/UIManager'
import { useAuth0 } from '@auth0/auth0-react'

import getConfig from "next/config";
import Modal from 'components/form/modal'

const { publicRuntimeConfig } = getConfig();

type TAuthState = 'LOADING' | 'UNAUTHORIZED' | 'UNLOGGED' | 'ALLOWED'

const {
    AUTH0_namespace
} = publicRuntimeConfig

const Layout: FC = ({ children }) => {
    const { user,
        isAuthenticated,
        isLoading,
        logout,
        loginWithRedirect
    } = useAuth0();


    const [authState, setAuthState] = useState<TAuthState>('LOADING')

    useEffect(() => {
        if (isLoading) {
            setAuthState("LOADING")

        }
    }, [isLoading])

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            setAuthState("UNLOGGED")
        }
    }, [isAuthenticated, isLoading])

    useEffect(() => {
        if (user) {
            if (!user[`${AUTH0_namespace}isAdmin`]) {
                setAuthState("UNAUTHORIZED")
            } else {
                setAuthState("ALLOWED")
            }
        }
    }, [user, isAuthenticated, isLoading])

    if (authState == "LOADING") return <div>Is loading</div>
    if (authState == "UNAUTHORIZED") return <div>
        Unauthorized account
        <button onClick={() => { logout() }}>
            log out
        </button>
    </div>
    if (authState == "UNLOGGED") {
        { loginWithRedirect() }
    }
    if (authState == "ALLOWED") {
        return <div className={cn(s.root)}>
            <ManagedUIContext>
                <Modal />
                <Sidebar />
                <Content>
                    {children}
                </Content>
                <Footer />
            </ManagedUIContext>
        </div>
    }
    return <div></div>
}

export default Layout;