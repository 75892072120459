import Image from 'next/image';

export const Logo = () =>{
    return <Image 
        src = "/logos/sidebar_logo.png"
        width = "190"
        height = "95"
        layout = "intrinsic"
        alt = "LOGO"
    />
}