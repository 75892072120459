import Image, { ImageProps } from 'next/image';
import { FC, useEffect, useRef } from 'react'

interface IImage {
    src?: string,
    width?: number | string,
    height?: number | string
}
export const Users = ({ width = "24", height = "24", src = "/icons/Users.png" }: IImage) => <Image alt="icon" width={width} height={height} src={src} />
export const Sales = ({ width = "24", height = "24", src = "/icons/Dollar.png" }: IImage) => <Image alt="icon" width={width} height={height} src={src} layout="intrinsic" />
export const Library = ({ width = "24", height = "24", src = "/icons/Library.png" }: IImage) => <Image alt="icon" width={width} height={height} src={src} />
export const Payout = ({ width = "24", height = "24", src = "/icons/Credit-card.png" }: IImage) => <Image alt="icon" width={width} height={height} src={src} />
export const Chart = ({ width = "24", height = "24", src = "/icons/Chart-bar-3.png" }: IImage) => <Image alt="icon" width={width} height={height} src={src} />
export const Blocks = ({ width = "24", height = "24", src = "/icons/Blocks.png" }: IImage) => <Image alt="icon" width={width} height={height} src={src} />
export const Chat = ({ width = "24", height = "24", src = "/icons/Chat.png" }: IImage) => <Image alt="icon" width={width} height={height} src={src} />
export const Settings = ({ width = "24", height = "24", src = "/icons/Settings.png" }: IImage) => <Image alt="icon" width={width} height={height} src={src} />
export const Signout = ({ width = "24", height = "24", src = "/icons/Signout.png" }: IImage) => <Image alt="icon" width={width} height={height} src={src} />
export const ArrowFromRight = ({ width = "24", height = "24", src = "/icons/ArrowFromRight.png" }: IImage) => <Image alt="icon" width={width} height={height} src={src} />
export const ArrowToRight = ({ width = "24", height = "24", src = "/icons/ArrowToRight.png" }: IImage) => <Image alt="icon" width={width} height={height} src={src} />
export const Calendar = ({ width = "32", height = "32", src = "/icons/Calendar.png" }: IImage) => <Image alt="icon" width={width} height={height} src={src} />
export const Notification = ({ width = "32", height = "32", src = "/icons/Notification.png" }: IImage) => <Image alt="icon" width={width} height={height} src={src} />
export const ChevronRight = ({ width = "24", height = "24", src = "/icons/ChevronRight.png" }: IImage) => <Image alt="icon" width={width} height={height} src={src} />
export const Stocks = ({ width = "20", height = "12", src = "/icons/StockIcon.png" }: IImage) => <Image alt="icon" width={width} height={height} src={src} />
export const Filters = ({ width = "15", height = "16", src = "/icons/Filters.png" }: IImage) => <Image alt="icon" width={width} height={height} src={src} />
export const ArrowDown = ({ width = "10", height = "6", src = "/icons/ArrowDown.png" }: IImage) => <Image alt="icon" width={width} height={height} src={src} />
