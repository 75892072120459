import cn from 'classnames'
import React, { FC } from 'react'
import s from './Modal.module.css'
import Portal from '@reach/portal'
import { IModal } from 'types/Form'
import { useUI } from 'contexts/UI/UIManager'

const Modal: FC<IModal> = () => {
    const {
        modalContent,
        modalVariant,
        modalIsOpened
    } = useUI();
    return <Portal>
        <div className={cn(s.root, { [s.hide]: !modalIsOpened })}>
            <div className={cn(s.modal,
                { [s.prompt]: modalVariant == "prompt" },
                { [s.greeter]: modalVariant == "greeter" }
            )}>
                {modalContent}
            </div>
        </div>
    </Portal>
}

export default Modal;