import s from './Content.module.css'
import { FC, useEffect, useRef } from 'react'
import cn from 'classnames';
import Header from '../header';


const Content: FC = ({ children }) => {

    return <div className={cn(s.root)}>
        <div >
            {children}
        </div>
    </div>
}

export default Content;