import { FC, useEffect, useRef } from 'react'
import s from './Card.module.css'
import cn from 'classnames'
import { ICustomComponent } from 'types/UI'

interface ICard extends ICustomComponent {
    cols?: number
    rows?: number
    onClick?: any
}

const Card: FC<ICard> = ({ children, cols = 1, rows = 1, className, onClick }) => {
    return <div
        onClick={onClick}
        className={cn(s.root,
            { [s.cols1]: cols == 1 },
            { [s.cols2]: cols == 2 },
            { [s.cols3]: cols == 3 },
            { [s.cols4]: cols == 4 },
            { [s.cols5]: cols == 5 },
            { [s.cols6]: cols == 6 },
            { [s.cols7]: cols == 7 },
            { [s.cols8]: cols == 8 },
            { [s.cols9]: cols == 9 },
            { [s.cols10]: cols == 10 },
            { [s.cols11]: cols == 11 },
            { [s.cols12]: cols == 12 },
            { [s.rows1]: rows == 1 },
            { [s.rows2]: rows == 2 },
            { [s.rows3]: rows == 3 },
            { [s.rows4]: rows == 4 },
            { [s.rows5]: rows == 5 },
            { [s.rows6]: rows == 6 },
            { [s.rows7]: rows == 7 },
            { [s.rows8]: rows == 8 },
            { [s.rows9]: rows == 9 },
            { [s.rows10]: rows == 10 },
            { [s.rows11]: rows == 11 },
            { [s.rows12]: rows == 12 },
            className
        )}>
        {children}
    </div>
}

export default Card;