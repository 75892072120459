import s from './Avatar.module.css'
import cn from 'classnames'
import { FC, useEffect, useRef } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Image from 'next/image'
import { useUI } from 'contexts/UI/UIManager'


const Avatar = () => {
    const {
        sidebarCollapsed,
    } = useUI();

    const {
        user,
    } = useAuth0();

    return <div className={cn(s.root, { [s.minimized]: sidebarCollapsed })}>
        <div className={cn(s.picture)}>
            <Image
                unoptimized={true}
                layout="fill"
                objectFit="cover"
                objectPosition="center"
                alt={"avatar"}
                src={user?.picture ?? "/sidebar/avatar.png"}
            />
        </div>
        <div className = {cn(s.info)}>
            <span>
                <strong>
                    {user?.nickname}
                </strong>
            </span>
            <br />
            <span>
                Admin
            </span>
        </div>
    </div>
}

export default Avatar;